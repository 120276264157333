import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AllPayments, userHasCardOnFile, CELL_SUBCRIPTIONS, USE_NEW_CELL_PLAN } from '.'
import { path } from 'ramda'
import classNames from 'classnames'
import { billingSubscribe } from './billingAccess'
import { Loader } from '../../components';

export default function CellPlanModal({ user, currentDevice, userActions }) {
  const [hasPaymentMethod, setHasPaymentMethod] = useState(userHasCardOnFile(user)) //returns id (like pm_1MPT3...)
  const [paid, setPaid] = useState(false)
  const [loading, setLoading] = useState(false)
  const macAddress = path(['macAddress'], currentDevice)
  const deviceName = path(['info', 'name'], currentDevice)
  const userId = path(['info', '_id'], user)

  const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  async function btnBuyHandler () {
    setLoading(true)
    const subscribed = await billingSubscribe(userId, macAddress, `kestrelmet-one-year-subscription${USE_NEW_CELL_PLAN ? '-new' : ''}`)
    await sleep(7000)  // Give billing server time to update the user object
    await userActions.refreshUser()
    setLoading(false)
    setPaid(subscribed)
  }

  const noCard = 
    <div>
      In order to add a cellular plan for your station, you must first have a credit card on file.  Please return to your Account page and add one.
    </div>

  const planIndex = USE_NEW_CELL_PLAN ? 0 : 1
  const addSubscription = 
    <div className="tiers">
      <div className={classNames('tier', 'active')}>
        <div className='top'>
          <h3>{CELL_SUBCRIPTIONS[planIndex].label}</h3>
          <h2>${CELL_SUBCRIPTIONS[planIndex].price}*</h2>
          <button
            className='btn btn-primary'
            disabled={!hasPaymentMethod || loading}
            onClick={btnBuyHandler}
          >Purchase</button>
          {loading ? <><br /><br /><p>Please wait</p><Loader /></> : ''}
        </div>
        <div className='bottom'>
          <div className='group'>
            <div className='line'>
              <div className='label'>Station Name:</div>
              <div className='val'>{deviceName}</div>
            </div>
            <div className='line'>
              <div className='label'>Station MAC:</div>
              <div className='val'>{macAddress}</div>
            </div>
          </div>
        <br />* Plus tax as applicable by the laws of your state.
        </div>
      </div>
    </div>

  const successMsg = 
    <div>
          <h1>You have successfully subscribed to a year of cellular service for your KestrelMet6000.</h1>
          <p>You can cancel control auto-renewal at any time under your Accounts page.</p>
          <p>There may be a delay for payment processing before the plan is activated.</p>
    </div>

return (
    <div className="payment-cell-plan-modal">
      {paid ? successMsg :
        hasPaymentMethod ? addSubscription : noCard
      }
    </div>
  );
};

CellPlanModal.propTypes = {
  currentDevice: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired
}
CellPlanModal.defaultProps = {};

CellPlanModal.displayName = 'CellPlanModal'