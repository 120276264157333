import React, { useEffect } from 'react'
import { Autocomplete } from '../common'
import PropTypes from 'prop-types'
import { FindLocationBtn } from '../../components'
import { getGeocode, findLocationFromGoogleRes } from '../../common/ambient'
import classNames from 'classnames'
import { path } from 'ramda'
import { useSetOnline } from './redux/setOnline'

const Geocode = getGeocode()

const callIfCallable = (obj, pth) => typeof path(pth, obj) === 'function' ? path(pth, obj)() : path(pth, obj)

export default function LocationAutocomplete ({ className, onClose, onFocus, onBlur, onChange, onPlaceSelected, placeholder }) {
  const [value, setValue] = React.useState('')
  const [focused, setFocused] = React.useState(false)
  const { online } = useSetOnline()
  useEffect(() => {
    if (onChange) {
      onChange(value)
    }
  }, [value, onChange])
  const doGoogleRes = res => {
    if (res && onPlaceSelected) {
      const lat = callIfCallable(res, ['geometry', 'location', 'lat'])
      const lon = callIfCallable(res, ['geometry', 'location', 'lng'])
      // mimic lastLocationSearch format
      if (lat && lon) {
        onPlaceSelected({
          label: res.formatted_address,
          location: findLocationFromGoogleRes(res.address_components),
          coords: {
            lat,
            lon
          },
          geo: {
            type: 'Point',
            coordinates: [lon, lat]
          }
        })
      }
    }
  }
  return (
    <div className={classNames('common-location-autocomplete', { 'has-value': value !== '' }, className)}>
      <FindLocationBtn
        onChange={location => {
          Geocode.fromLatLng(location.coords.latitude, location.coords.longitude)
            .then(res => {
              doGoogleRes(path(['results'], res)[0])
            })
        }}
      />
      <div className='autocomplete-wrap'>
        {online &&
          <Autocomplete
            types={[]}
            type='text'
            className='autocomplete'
            placeholder={(!focused && placeholder) || 'Search for your location'}
            value={value}
            onFocus={() => {
              setFocused(true)
              if (onFocus) {
                onFocus()
              }
            }}
            onBlur={() => {
              setFocused(false)
              if (onBlur) {
                onBlur()
              }
            }}
            onPlaceSelected={(res, inputRef) => {
              setValue('')
              setFocused(false)
              doGoogleRes(res)
            }}
            onChange={evt => {
              setValue(evt.target.value)
            }}
          />}
        <a
          onClick={() => {
            setValue('')
            if (onClose) {
              onClose()
            }
          }} className='glyphicon x-gray'
        />
      </div>
    </div>
  )
};

LocationAutocomplete.propTypes = {
  onClose: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
}
LocationAutocomplete.defaultProps = {}

LocationAutocomplete.displayName = 'LocationAutocomplete'


LocationAutocomplete.displayName = 'LocationAutocomplete'

LocationAutocomplete.displayName = 'LocationAutocomplete'
