import {
  FETCH_DEVICE_DATA_BEGIN,
  FETCH_DEVICE_DATA_SUCCESS,
  FETCH_DEVICE_DATA_FAILURE,
  FETCH_DEVICE_DATA_DISMISS_ERROR,
  FETCH_DEVICE_DATA_CLEAR_HIRES,
  FETCH_DEVICE_DATA_CLEAR_DATA_KEY,
} from './constants'
import { app } from '../../../common/feathers'
import { reduce, pluck, head, uniqBy, flatten, map, values, groupBy, prop, pipe, contains, filter, omit, clone, dissoc } from 'ramda'
import { isLoggedIn, reduceSummaries, getSummaryDocDate, isSomething, getDisplayKeysFromState, removeFromArr, rowsHave } from '../../../common/ambient'
import { removeDeviceData } from './removeDeviceData'
import { updateDeviceData } from './updateDeviceData'
const dataService = app.service('device-data')

let cache = {}

export function clearFetchDeviceDataCache (args) {
  let cacheKey = JSON.stringify(dissoc('dataKey', args))
  delete cache[cacheKey]
  return {
    type: 'CLEAR_FETCH_DEVICE_DATA_CACHE'
  }
}

export function fetchDeviceData (args) {
  return (dispatch, getState) => {
    if (!args.dataKey) {
      args.dataKey = 'dataTableData'
    }
    const { device, user } = getState()
    const { fetchDeviceDataKeysPending } = device
    if (contains(args.dataKey, fetchDeviceDataKeysPending)) {
      return Promise.resolve(true)
    }

    dispatch({
      type: FETCH_DEVICE_DATA_CLEAR_DATA_KEY,
      dataKey: args.dataKey
    })
    dispatch({
      type: FETCH_DEVICE_DATA_BEGIN,
      dataKey: args.dataKey,
      data: args
    })
    let cacheKey = JSON.stringify(dissoc('dataKey', args))
    if (cache[cacheKey]) {
      dispatch({
        type: FETCH_DEVICE_DATA_SUCCESS,
        data: cache[cacheKey],
        dataKey: args.dataKey
      })
      return Promise.resolve(cache[cacheKey])
    }
    let data
    return dataService.find({
      query: args
    })
      .then(function (res) {
        data = filter(isSomething, res.data)
          .map(d => {
            d.res = args.res || 5
            return d
          })

        // handle duplicate summaries
        if (args.stats === 'summary') {

          data = pipe(
            groupBy(pipe(prop('dateutc'), getSummaryDocDate(moment, device))),
            values,
            map(docs => {
              if (docs.length === 1) {
                return docs[0]
              }
              // we found dups!
              const mergedDoc = reduceSummaries(docs) // merge them
              ;(['_id', '_rev', 'type', 'PASSKEY', 'dateutc', 'loc']).forEach(prop => mergedDoc[prop] = docs[0][prop]) // add internal stuff
              // updateDeviceData(mergedDoc._id, mergedDoc, {})(dispatch, getState) // update the first
              // removeDeviceData(docs.slice(1))(dispatch, getState) // remove the others
              return mergedDoc
            }),
            map(doc => {
              // remove erroneous lightning params before June 1st 2020
              if (doc.dateutc < 1591034724000) {
                return omit(['lightning_day', 'lightning_hour'], doc)
              }
              return doc
            }),
            map(doc => {
              if (!doc._id) {
                doc._id = doc.dateutc + '-' + doc.PASSKEY
              }
              return doc
            })
          )(data)
        } else if (rowsHave(data, 'lightnings')) {
          const allLightnings = pipe(
            pluck(('lightnings')),
            filter(isSomething),
            reduce((acc, val) => acc.concat(val), []),
            uniqBy(head)
          )(data)
          data = data.map(d => {
            const myLightnings = allLightnings.filter(l => l[0] <= d.dateutc && l[0] >= d.dateutc - d.res * 60 * 1000)
            d.lightning_res = myLightnings.reduce((acc, val) => acc + val[1], 0)
            return d
          })
        }


        cache[cacheKey] = data
        dispatch({
          type: FETCH_DEVICE_DATA_SUCCESS,
          data,
          dataKey: args.dataKey
        })
        return data
      })
      .catch(function (err) {
        dispatch({
          type: FETCH_DEVICE_DATA_FAILURE,
          data: {
            error: err,
          },
          dataKey: args.dataKey
        })
      })
  }
}

export function dismissFetchDeviceDataError () {
  return {
    type: FETCH_DEVICE_DATA_DISMISS_ERROR,
  }
}

export function clearHiresData () {
  return {
    type: FETCH_DEVICE_DATA_CLEAR_DATA_KEY,
    dataKey: 'hiresData'
  }
}

export function clearDataKey (dataKey) {
  return {
    type: FETCH_DEVICE_DATA_CLEAR_DATA_KEY,
    dataKey: dataKey
  }
}

function getAllDisplayKeys (state) {
  return getDisplayKeysFromState(state.deviceDataAllDataKeys, state)
}
function cleanPublicData (data, state) {
  const publicDevice = state.fetchedDevices[0]
  if (!publicDevice) return data
  if (publicDevice.info.indoor) return data
  return data.map(omit([
    'tempinf',
    'humidityin',
    'temp1f',
    'temp2f',
    'temp3f',
    'temp4f',
    'temp5f',
    'temp6f',
    'temp7f',
    'temp8f',
    'temp9f',
    'temp10f',
    'humidity1',
    'humidity2',
    'humidity3',
    'humidity4',
    'humidity5',
    'humidity6',
    'humidity7',
    'humidity8',
    'humidity9',
    'humidity10'
  ]))
}
export function reducer (state, action) {
  let ret
  switch (action.type) {
    case FETCH_DEVICE_DATA_BEGIN:
      state.fetchDeviceDataKeysPending.push(action.dataKey)
      return {
        ...state,
        fetchDeviceDataPending: true,
        fetchDeviceDataError: null,
      }

    case FETCH_DEVICE_DATA_SUCCESS:
      ret = {
        ...state,
        fetchDeviceDataPending: false,
        fetchDeviceDataError: null,
      }
      ret[action.dataKey] = action.data
      ret.deviceDataAllDataKeys.push(action.dataKey)
      ret.fetchDeviceDataKeysPending = removeFromArr(action.dataKey, ret.fetchDeviceDataKeysPending)
      ret.deviceDataAllDisplayKeys = getAllDisplayKeys(ret)
      return ret

    case FETCH_DEVICE_DATA_FAILURE:
      ret = {
        ...state,
        fetchDeviceDataPending: false,
        fetchDeviceDataError: action.data.error,
      }
      ret.fetchDeviceDataKeysPending = removeFromArr(action.dataKey, ret.fetchDeviceDataKeysPending)
      return ret

    case FETCH_DEVICE_DATA_DISMISS_ERROR:
      return {
        ...state,
        fetchDeviceDataError: null,
      }

    case FETCH_DEVICE_DATA_CLEAR_HIRES:
      return {
        ...state,
        hiresData: null,
      }
    case FETCH_DEVICE_DATA_CLEAR_DATA_KEY:
      ret = {
        ...state
      } 
      delete ret[action.dataKey]
      ret.deviceDataAllDataKeys = removeFromArr(action.dataKey, ret.deviceDataAllDataKeys)
      ret.deviceDataAllDisplayKeys = getAllDisplayKeys(ret)
      return ret 

    default:
      return state
  }
}
