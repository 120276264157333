import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames'
import {
  MacAddressInput,
  DeviceEditForm,
  DeviceTypeChooser
} from './'
import {
  HelpLinks
} from '../../components'
import { getThemeObj } from '../../common/skinner';
import { Link, withRouter } from 'react-router-dom'
import { toggleDevicePublic, pathsChanged, getUrlQueryParam, isBillingDev, accuweatherUrl } from '../../common/ambient';
import { 
  USE_NEW_CELL_PLAN,
  AllPayments,
  deviceHasSubscription,
  userHasCardOnFile
} from '../payment/'
import {
  billingSubscribe
} from '../payment/billingAccess'
import { path } from 'ramda';
import { isCordova } from '../../common/ambient'

class CreateDevice extends PureComponent {
  static propTypes = {
    device: PropTypes.object.isRequired,
    deviceActions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    userActions: PropTypes.object.isRequired,
  }
  state = {
    isCellular : false
  }

  constructor(props) {
    super(props)
    this._reset = ::this._reset
    this.debug = false
  }

  _checkNoDevices() {
    const { devices } = this.props.device
    const isOpen = !this.manuallyClosed 
      && (
        (devices && devices.length < 1) 
        || getUrlQueryParam(this.props.location, 'macAddress')
        || getUrlQueryParam(this.props.location, 'mac')
        || getUrlQueryParam(this.props.location, 'ref')
        || getUrlQueryParam(this.props.location, 'open')
      )
    if (isOpen) {
      this.setState({
        open: true
      })
    }
  }
  componentDidMount() {
    this._reset()
    this._checkNoDevices()
  }
  componentDidUpdate(prevProps) {
    this._checkNoDevices()
  }
  macAddressChange(res) {
    const { currentDevice } = this.state
    if (res.isValid) {
      currentDevice.macAddress = res.macAddress
      
      if (!this.props.device.checkMacAddressPending) {
        this.props.deviceActions.checkMacAddress(res.macAddress)
      }
    } else {
      currentDevice.macAddress = '' 
    }
    this.setState({ currentDevice: Object.assign({}, currentDevice) })
  }
  _isWxw() {
    return this.props.device.checkMacAddressLastMacWxw
  }
  _skipStationChooser() {
    return this._isWxw() || this.props.device.checkMacAddressLastMacRw || this._isAccuweather() 
  }
  _reset() {
    this.setState({
      open: false,
      creating: false,
      // paid: false,
      showPayment: false,
      showLocation: false,
      showChooser: false,
      location: false,
      isCellular: false,
      currentDevice: {
        info: {
          name: ''
        },
        tz: {},
        macAddress: '',
        summaryMigrated: Date.now()
      }
    })
    if (this.macInput) this.macInput.clear()
  }
  _notOk() {
    const { currentDevice } = this.state
    const { device } = this.props
    const { checkMacAddressPending, macAddressExists } = device
    return currentDevice.macAddress === '' ||  checkMacAddressPending || macAddressExists
  }

  async _checkIfCellular() {
    const mac = this.state.currentDevice.macAddress
    if(this.debug) console.log("_checkIfCellular // mac = " + mac)
    // If MAC doesn't start with 30: then it is not a KestrelMet.  Avoid unnecessary fetch.
    if (!mac.startsWith('30:')) {
      if(this.debug) console.log("_checkIfCellular // mac != ^30:")
      this.setState({ isCellular: false })
      return false      
    }

    if (isBillingDev()) {  // Turn off live check of RWN 
      this.setState({ isCellular: true })
      return true
    }
    
    const rwn_resp = await fetch(`https://diagnostics.rainwise.net/mk4?mac=${mac.replace(/:/g, '')}&ignore_serial=true`)
    const rwn_json = await rwn_resp.json()
    if(this.debug) console.log("_checkIfCellular // rwn_json = " + JSON.stringify(rwn_json, 0, 2))
    if (rwn_json.stationType === "KestrelMet6000") {  // KestrelMet6000W is a WiFi station (note the W)
      if(this.debug) console.log("_checkIfCellular // Asserting stationType is KestrelMet6000")
      this.setState({ isCellular: true })
      return true
    }

    // Nils TODO:  Handle failed fetch.  If it fails and it was a KM6k then... free cell service for life!

    // It wasn't a KestrelMet6000
    if(this.debug) console.log("_checkIfCellular // It wasn't a KestrelMet6000")
    this.setState({ isCellular: false })
    return false
  }

  save() {
    const { deviceActions, user, history, device, userActions } = this.props
    const { currentDevice } = this.state
    const { devices } = device
    if (this._notOk()) return
    // default device name
    let defaultName = `My Weather ${this._isWxw() ? 'Window' : 'Station'}`
    if (this._isAccuweather()) {
      currentDevice.stationType = this._isAccuweather()
      defaultName = 'My AccuWeather Station'
    }
    if (currentDevice.info.name === '') {
      currentDevice.info.name = defaultName
    }
    // we need to completely remove the public param
    if (currentDevice.public === null) {
      delete currentDevice.public
    }
    this.setState({ creating: true })
    deviceActions.saveCurrent(currentDevice)
    .then(async dev => {
      const needsSubscription = await this._checkIfCellular()
      if (!needsSubscription) {
        if(this.debug) console.log("save // does not needsSubscription")
        this._reset()  
      } else { 
        if(this.debug) console.log("save // yes needsSubscription")
        // Must add subscription to station to cut cell service upon expiry (otherwise free cell for life!)
        // If user deleted and re-added their station, it will already have a subscription
        if (!deviceHasSubscription(user, dev.macAddress)) {
          if(this.debug) console.log("save // !deviceHasSubscription")
          const bill_resp = await billingSubscribe(user.info._id, dev.macAddress, 'kestrelmet-one-year-subscription-new')
          if(this.debug) console.log("save // billingSubscribe status = " + bill_resp.status)

          // Nils TODO:  Handle failed fetch.  If it fails and it was a KM6k then... free cell service for life!

        }
        // Subscription was added, attempt to collect credit card info for auto-renewal
        this.setState({ 
          showPayment: true, 
          showLocation: false, 
          currentDevice: dev,
          isCellular : true
        })
      }
      // check alexa for wxw
      if (this._isWxw() && devices.length === 1 && path(['info', 'oauth', 'alexa'], user)) {
        const alexa = user.info.alexa || {}
        alexa.lastDevice = devices[0]._id 
        userActions.patch(user.info._id, { alexa })
      }
      history.replace('/devices')
      this._reset()
      return deviceActions.fetchDevices()
    })
  }
  _isAccuweather() {
    return accuweatherUrl(this.props.location) 
  }

  _macSubscriptionEnds(user, macAddress) {
    const subs = path(["info", "payments", "subscriptions"], user)
    if (!subs) return null
    const sub = subs.find (function(x) {
      return x.macAddress === this
    }, macAddress)
    return (sub && moment(sub.currentPeriodEnd * 1000).format('MMM, D, YYYY'))
  }

  render() {
    const { showChooser, showLocation, open, currentDevice, showPayment, isCellular, creating } = this.state
    const { device, user } = this.props
    const { checkMacAddressPending, macAddressExists } = device
    if (!currentDevice) return <div />
    const macAddress = currentDevice.macAddress
    const openForm = (evt) => {
      this.setState({
        open: !open
      })
      if (open) {
        this._reset()
      }
      evt.preventDefault()
    }
    let macMessage = ''
    let macStatus = ''
    if (currentDevice.macAddress !== '') {
      if (checkMacAddressPending) {
        macMessage = <span className="help-block">One sec..</span>
      } else if (macAddressExists) {
        macStatus = 'has-error'
        macMessage = <div className="error">
          <div className="help-block">That MAC address already exists, please check the number for accuracy.</div>
          <p>
            You may have a duplicate account. <a onClick={this.props.userActions.logoutUser.bind(this)}>Log out to switch accounts.</a>
          </p>
          <p>
            Still need help? Contact <a href="mailto:support@ambientweather.com">support@ambientweather.com</a>. Please include your MAC address.
          </p>
        </div>
      } else {
        if (isCellular && isCordova()) {
          macStatus = 'has-error'
          macMessage = <span className="help-block">This model can only be registered on the website.  Please visit www.AmbientWeather.net to register.</span>
        } else {
          macStatus = 'has-success'
          macMessage = <span className="help-block">Looks good!</span>
        }
      }
    } else {
      macMessage = ''
    }

    // step 1.
    let show = <div className="step-1">
        <p>Enter your device's MAC address. 
          {(getThemeObj().token != 'km') && <>&nbsp;<a href="https://ambientweather.com/faqs/question/view/id/1450/" target="_blank">How to find my MAC address</a></>}
        </p>
      <div className={classNames("form-group mac", macStatus)}>
        <MacAddressInput ref={mac => this.macInput = mac} onChange={this.macAddressChange.bind(this)} className="form-control" />
      </div>
      <div className={classNames("mac-message", macStatus)}>
        {macMessage}
      </div>
      {<button className="btn-long btn btn-primary" onClick={() => {
        let st8 = { showLocation: true }
        // make public by default on Ambient but not KestrelMet
        if (getThemeObj().sharePublicly && !this._isWxw()) {
          st8.currentDevice = toggleDevicePublic(currentDevice)
        }
        if (this._skipStationChooser()) {
          this.setState(st8)        
        } else {
          this.setState({ ...st8, showLocation: false, showChooser: true })
        }
      }} disabled={this._notOk() || macStatus === 'has-error'}>Next</button>}
      <HelpLinks />
    </div>

    if (showChooser) {
      show = <DeviceTypeChooser 
        onSuccess={stationType => {
          this.setState({ 
            showLocation: true, 
            showChooser: false, 
            currentDevice: { ...currentDevice, stationType } 
          })
        }}
      />
    }

    if (showLocation) {
      let namePlaceholder = null
      if (this._isWxw()) {
        namePlaceholder = 'Ex: My Weather Window'
      } else if (this._isAccuweather()) {
        namePlaceholder = 'Ex: My AccuWeather Station'
      }
      show = <DeviceEditForm 
          currentDevice={currentDevice} 
          className={{ wxw: this._isWxw() }}
          namePlaceholder={namePlaceholder}
          onChange={cd => this.setState({ currentDevice: cd })} 
        >
            <div className="btns-wrap">
              <button className="btn-long btn btn-primary" onClick={this.save.bind(this)} disabled={this._notOk() || creating}>Creat{creating ? 'ing...' : 'e'}</button>
          </div>
        </DeviceEditForm>
    }

    // step 3, for cellular stations have user add valid form of payment for plan renewal
    if (showPayment) {
      const userId = path(['info', '_id'], user)
      const deviceId = path(['_id'], currentDevice)
      let howLong = 'one free year'
      let howMuch = 'year'
      if (USE_NEW_CELL_PLAN) {
        howLong = 'three free months'
        howMuch = 'three months'
      }
      const newNotice = <p>Congratulations!  Your new cellular weather station includes {howLong} of cellular service, starting today.</p>
      const endDate = this._macSubscriptionEnds(user, macAddress)
      const endDateNotice = <p>The cellular plan subscription for this station ends on: {endDate}</p>
      show = <div className="step-2">
        <h2>Cellular Access Plan</h2>
        { endDate ? endDateNotice : newNotice }
        {userHasCardOnFile(user)
          ? <>
            <p>We'll use your card on file to renew this subscription automatically at the expiration date.</p>
            <p>If you ever need to change your auto-renew settings you can do that on your Account page.</p>
            <button className="btn-long btn btn-primary" onClick={this._reset}>Complete</button>
          </>          
          : <>
              <p>In order to prevent loss of connection after the first {howMuch}, please file a credit card with us to auto-renew your service.  Your card will NOT be charged now and you can cancel the auto-renewal any time under your Account tab.</p>
              <br/>
              <AllPayments 
                userId={userId} 
                deviceId={deviceId} 
                mode="register" 
                // onSuccess={paid => this.setState({ paid })} 
                onSuccess={this._reset} 
              />
              <a className='skip' onClick={this._reset}>Skip this step</a>
            </>
        }
      </div>
    }

    return (
      <div className={classNames("device-create-device block", { open, location: showLocation, payment: showPayment })}>
        <a onClick={openForm} className="connect">Connect a New Device</a>
        <a onClick={evt => {
          this.manuallyClosed = true
          openForm(evt)
        }} className="close"></a>
        <div className="body">
          {show}
        </div>
      </div>
    );
  }
}

export default withRouter(CreateDevice)

CreateDevice.displayName = 'CreateDevice'
