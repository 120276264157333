import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { contains, mapObjIndexed, pick, not, isEmpty, filter, pipe, path } from 'ramda'
import classNames from 'classnames'
import TimezonePicker from '../../components/TimezonePicker'
import Location from '../../components/Location'
import {
  CreateWebcam,
  ShareDeviceModal,
  ServersForm,
  DeviceEditForm,
  Accessories,
  DeviceTypeChooser
} from './'
import { cleanDeviceToStore, getDeviceType, getDeviceTypeLabel, getUserSetting, hasWxwConfig, isAccuweatherDevice, isAdmin, isDev, isRole, isWxw } from '../../common/ambient';
import { getBusinessName, skinHasFeature, getThemeObj } from '../../common/skinner.js'
import CellStatus from '../../components/CellStatus'
import { postToRainWise } from '../../common/postToRainWise';
import { billingSetAutorenew } from '../payment/billingAccess';
import { hasRainWiseStatus, hasCellularSubscription, isCellularDevice } from '../payment';

export default class DeviceLine extends PureComponent {
    static propTypes = {
    alerts: PropTypes.array,
    currentDevice: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    deviceActions: PropTypes.object.isRequired,
    userActions: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
  };

  state = {
    open: false,
    showLocation: false,
    editLocation: false,
    showShare: false,
    intro: false,
  }


  tabs = {
    aw: {
      label: 'Ambient Weather'
    },
    km_aw: { // The AW tab shown on a Kestrel skinned site
      label: 'Ambient Weather'
    },
    pws: {
      label: 'PWS Weather'
    },
    wu: {
      label: 'Weather Underground'
    },
    aa: {
      label: 'Amazon Alexa'
    },
    ga: {
      label: 'Google Home'
    },
    ift: {
      label: 'If This Then That'
    }
  }
  componentDidMount() {
    const { device, currentDevice } = this.props
    const { recentlyCreated } = device
    if (recentlyCreated && recentlyCreated._id === currentDevice._id) {
      this.setState({
        open: 'aw',
        intro: true
      })
    }
  }
  getInput(dev, field) {
    const { deviceActions } = this.props
    const val = dev.info ? dev.info[field] : ''
    return (
      <input
        type="text"
        value={val}
        className={'editable-title type-info-' + field}
        onChange={(evt) => {
          const info = dev.info || {}
          info[field] = evt.target.value
          deviceActions.patch(dev._id, { info })
        }}
      />
    )
  }
  removeDevice() {
    if (window.confirm('Are you sure you want to delete this device?')) {
      const{ userActions } = this.props
      const { user } = this.props
      const { currentDevice } = this.props
      const { removeDevice, fetchDevices, setDeviceI } = this.props.deviceActions
      const subs = path(['info', 'payments', 'subscriptions'], user)
      const userId = path(['info', '_id'], user)
      // const deviceId = path(['_id'], currentDevice)
      const deviceId = path(['macAddress'], currentDevice)

      if (subs) {
        const turnOffRenew = async (subs) => {
          let successAll = true
          for (let i = 0; i < subs.length; i++) {
            const thisDeviceId = path(['macAddress'], subs[i]) 
            if (thisDeviceId === deviceId) {
              const productName = path(['productName'], subs[i])
              const success = billingSetAutorenew(userId, deviceId, productName, false)
              if (!success) {
                successAll = false
              } else {
                const newUser = Object.assign({}, user.info)
                newUser.payments.subscriptions[i].cancelAtPeriodEnd = false
                await userActions.refreshUser(newUser)
              }
            }
          }
          return successAll
        }
        const success = turnOffRenew(subs)
      }
      removeDevice(currentDevice).then(() => fetchDevices())
      setDeviceI(0)
    }
  }
  badge(on) {
    return <div className={classNames("badge", { on })} />
  }
  _speechDefault(service) {
    const { userActions, currentDevice, user } = this.props
    const slug = service === 'aa' ? 'alexa' : 'google'
    const deviceId = path(['info', slug, 'lastDevice'], user)
    if (deviceId && currentDevice._id === deviceId) {
      return <p>This is the default device for this assistant.</p>
    }
    return <p>This is not your default device for this assistant. <a onClick={() => {
          const info = user.info[slug] || {}
          info.lastDevice = currentDevice._id 
          const obj = {}
          obj[slug] = info
          userActions.patch(user.info._id, obj)
      }} className="btn btn-primary">Make Default</a></p>
  }
  _tabContent(){
    const { showShare, editLocation, showLocation, open, intro } = this.state
    const{ userActions, user, currentDevice, deviceActions } = this.props
    if (open === 'pws' || open === 'wu') return <ServersForm theDevice={currentDevice} service={open} deviceActions={deviceActions} />
    if (open === 'aw') {
      if (intro) {
        let introCopy = (
          <>
            <h1>Your device is all set up!</h1>
            <p>
              See all those icons above? Those are the other services you can connect your devices to.  Once a service has been connected you'll see a green check underneath its icon.
            </p>
            {skinHasFeature("aa") ? ( 
                <p>
                  Share your dashboard with friends, contribute your data to worldwide networks, ask Alexa to update you on the temperature outside YOUR house, and use IFTTT to set up the smart home that you’ve always dreamed of.
                </p>
              ) : (
                <p>
                  By logging into our sister site AmbientWeather.net (with the same login and password) you have additional choices of consumer systems such as Amazon Alexa and IFTTT. 
                </p>
              )
            }
            <div className="btns">
              <a onClick={() => this.setState({ intro: false })} className="close-intro">Close</a>
              <Link to={'/dashboard'} className="btn btn-primary" >View Dashboard</Link>
            </div>
          </>
        )
        if (isWxw(currentDevice)) {
          introCopy = (
            <>
              <h1>Your Weather Window is now connected to AWN!</h1>
              <p>Pro tip: To easily include measurements from stations other than your own, add them your "favorites" list by clicking the <span className='favstar on' /> next to the name.</p>
              <div className="btns">
                <a onClick={() => this.setState({ intro: false, open: false })} className="close-intro">Close</a>
                <Link to={'/wxw'} className="btn btn-primary" >Configure your Weather Window</Link>
              </div>
            </>
          )
        }
        if (isAccuweatherDevice(currentDevice)) {
          introCopy = (
            <>
              <h1>Your device is all set up!</h1>
              <p>Congratulations, you've now registered your AccuWeather Station. It's time to connect your station to AWN. Follow the instructions in your manual to connect your station to Wi-Fi. Once you connect your station to AWN, you can familiarize yourself with your weather dashboard, share posts with the community, favorite local stations, and interact with map layers!</p>
              <p> It's also time to enjoy your free 3-month AccuWeather Premium + subscription. About an hour after your station is connected to Wi-Fi and sends its first data, you will receive an email with instructions on redeeming your subscription. Valid for new Premium + users only.</p>
              <div className="btns">
                <a onClick={() => this.setState({ intro: false })} className="close-intro">Close</a>
                <Link to={'/dashboard'} className="btn btn-primary" >View Dashboard</Link>
              </div>
            </>
          )
        }
        return <div className="intro">
          {introCopy}
        </div>
      }
      if (showShare) {
        return <div className="share-tab">
          <a className="close" onClick={() => this.setState({ showShare: false })}></a>
          <h2 className="share">Sharing <i className="btn btn-green glyphicon glyphicon-share-alt"></i></h2>
          <ShareDeviceModal bodyOnly={true} currentDevice={currentDevice} />
        </div>
      }
      return <div>
        <DeviceEditForm currentDevice={currentDevice} onChange={(cd, propChanged) => {
          const obj = {}
          obj[propChanged] = cd[propChanged]
          deviceActions.patch(currentDevice._id, obj)
            .then(res => {
              const d = res.result
              // also update recents
              let changed = false
              const recent = (getUserSetting('recent')(user) || []).map(recentDevice => {
                if (recentDevice.macAddress === d.macAddress) {
                  changed = true
                  return cleanDeviceToStore(d)
                }
                return recentDevice
              })
              if (changed) {
                userActions.updateSetting('recent', recent)
              }
            })
        }} />
        <div className="delete-device-wrap">
          <a className="delete-device" onClick={() => this.removeDevice()} >Delete this device</a>
        </div>
      </div>
    }
    if (open === 'aa') {
      if (this._tabActive('aa')) {
        return <div>
          <p>
            Your account is connected to Amazon Alexa! <a href="https://help.ambientweather.net/help/amazon-alexa/">Here is a list of ways you can use the skill ></a>
          </p>
          {this._speechDefault('aa')}
        </div>
      }
      return <div>
          <p>
            Use our <a href="https://www.amazon.com/dp/B074PGCM1D/">Amazon Alexa Skill</a> to get real-time, and past weather information from your devices.  Enable the skill to get started, then say ‘Alexa, ask Ambient Weather for a weather report.’
          </p>
          <p>
            <a href="https://help.ambientweather.net/help/amazon-alexa/">Read more about the skill here ></a>
          </p>
      </div>
    }
    if (open === 'ga') {
      // if (this._tabActive('ga')) {
      //   return <div>
      //     <p>
      //       Your account is connected to Google Home!
      //     </p>
      //   </div>
      // }
      return <div>
        <p>
          Google recently dropped support for Conversation Actions which is how AWN was integrated with Google Assistant.  Please bear with us as we work on integrating with Google again using their new method.
        </p>
        <p>
          {/* <a href="https://help.ambientweather.net/help/google-home/">Read more about the skill here ></a> */}
        </p>
      </div>
    }
    if (open === 'ift') return <div>
      <p>
        <a href="http:///ifttt.com">IFTTT</a> is a simple way for two devices that are connected to the Internet to communiate with each other.  It has become a standard for Home Automation and Smart Devices like Ambient Weather.
      </p>
        <p>
          <a href="https://help.ambientweather.net/help/ifttt/">Read more about all the ways you can use Ambient Weather and IFTTT here ></a>
        </p>
    </div>

    if (open === 'km_aw') return <div>
      <p>
      Kestrel Meters and Ambient Weather are partner companies under Nielsen-Kellerman Holdings.  As such, the account login for KestrelMet.net will work equally on <a target="_blank" href="https://ambientweather.net">AmbientWeather.net</a>, and your device data is visible on both online platforms.
      </p>
    </div>
    
  }
  _tabActive(tab){
    const { user, alerts, currentDevice } = this.props
    if (tab === 'ift') {
      return alert.alerts && pipe(
        filter(a => a.ifttt && a.deviceId === currentDevice._id),
        pipe(isEmpty, not)
      )(alerts)
    } else if (tab === 'ga') {
      return path(['info', 'oauth', 'google-assistant'], user)
    } else if (tab === 'aa') {
      return path(['info', 'oauth', 'alexa'], user)
    } else if (contains(tab, ['pws', 'wu'])) {
      return path(['servers', tab, 'verified'], currentDevice)
    } else if (tab === 'aw') {
      return currentDevice.public
    } else if (tab === 'km_aw') {
      return currentDevice.public
    }
    return false 
  }
  _subscription(){
    const { currentDevice, user } = this.props
    if (!isCellularDevice(currentDevice)) return
    const mac = path(['macAddress'], currentDevice)
    const userSubs = path(['info', 'payments', 'subscriptions'], user)
    const sub = userSubs.find(obj => obj.macAddress === mac && /kestrelmet-one-year-subscription/.test(obj.productName))
    let expiredMsg = <span className="cellLink">Expired.  <Link to={{pathname: '/account/subscriptions', hash: '#subscription-list'}}>Add new cell plan</Link></span>
    if (sub && sub.currentPeriodEnd) {
      const currentPeriodEnd = sub.currentPeriodEnd
      const endDate = moment(currentPeriodEnd * 1000).format('MMM, D, YYYY')
      const now = Date.now()
      const expired = now > currentPeriodEnd * 1000
      if (!expired) {
        expiredMsg = <span className="cellLink">Expires <Link to={{pathname: '/account/subscriptions', hash: '#subscription-list'}}>{endDate}</Link></span>
      }
    }
    return(
      <div className="item">
        <label>Cellular Plan: </label>
        {expiredMsg}
      </div>    
    )
  }
  _onClickService(service){
    const { showShare, open, intro } = this.state
    if (open === service) {
      if (service === 'aw' && showShare) {
        return this.setState({ showShare: false })
      }
      return this.setState({ open: false, intro: false, showShare: false })
    }
    this.setState({ open: service, intro: false, showShare: false })
  }
  _tabLine(){
    const { showShare, open, intro } = this.state
    return(
      Object.values(mapObjIndexed((obj, service) => 
        <a
          onClick={() => {
            this._onClickService(service)
          }}
          key={service}
          className={classNames('tab', service, {
              open: open === service,
              active: this._tabActive(service)
          })}
        >
        <span>
          {(service != 'aw') ? obj.label : getBusinessName()}
        </span></a>
      , this.tabs))
    )
  }
  render() {
    const { user, currentDevice, deviceActions, userActions } = this.props
    const { showShare, open } = this.state
    const dev = currentDevice
    let t
    if (dev.lastData) {
      t = moment(new Date(dev.lastData.dateutc))
    }
    let lastUpdated = t ? 'updated ' +  t.from(Date.now()) : 'not reporting...'
    const coordLocation = path(['info', 'coords', 'location'], currentDevice)
    Object.keys(this.tabs).forEach(key => {
      if (!skinHasFeature(key)){
        delete this.tabs[key]
      }
    })
    let thirdItem = (
      <div className="item">
        <label>Location: </label>
        <a onClick={() => this.setState({ open: 'aw', showLocation: true, editLocation: true })}>{coordLocation || '(not set)'}</a>
      </div>
    )
    if (isWxw(currentDevice)) {
      thirdItem = <a className="trash glyphicon glyphicon-trash delete-device" onClick={() => this.removeDevice()} />
      lastUpdated = ''
      if (!hasWxwConfig(currentDevice)) {
        lastUpdated = <Link to='/wxw'>Configure Weather Window</Link>
      }
    }
    const deviceType = getDeviceType(currentDevice)
    const onClickDeviceType = () => {
      if (['rw', 'kestrel', 'wxw'].includes(deviceType)) return
      userActions.doModal({ 
        type: 'component', 
        data: <DeviceTypeChooser 
          onSuccess={stationType => {
            deviceActions.patch(dev._id, { stationType })
            userActions.doModal('')
          }}  
        />
      })
    }
    return (
      <div key={dev._id} className={classNames('device-line v2', { 
        'no-type': !deviceType,
        'tz-focused': this.state.tzFocused,
      }, deviceType)}>
        <div className="block">
          <header>
            <div className="info-wrap">
              <div className='details'>
                <a className='type' onClick={onClickDeviceType} />
                <div>
                  {this.getInput(dev, 'name')}
                  <div className="sub-head clearfix">
                    <div className="item">
                      <label>MAC Address:</label>
                      <span className="mac">{dev.macAddress}</span>
                    </div>
                    <div className="item tz">
                      <label>Timezone: </label>
                      <TimezonePicker
                        value={dev.tz.name}
                        onFocus={() => this.setState({ tzFocused: true })}
                        onBlur={() => this.setState({ tzFocused: false })}
                        onChange={tz => {
                          if (tz.name !== dev.tz.name) {
                            deviceActions.patch(dev._id, { tz: pick(['name'], tz) })
                            //NPS add PostToRainwise here
                            const relayData = Object.assign({}, 
                              {"macAddress":dev.macAddress},
                              {"tz":{"name":tz.name}})
                            postToRainWise(relayData)                        
                          }
                        }}
                      />
                    </div>
                    {thirdItem}
                    {deviceType && (
                      <div className='item station-type'>
                        <label>Device Type:</label>
                        <a onClick={onClickDeviceType}>{getDeviceTypeLabel(currentDevice)}</a>
                      </div>
                    )}
                  </div>
                </div>
                {this._subscription()}
              </div>
              <Accessories deviceActions={deviceActions} currentDevice={currentDevice} />
              {hasRainWiseStatus(currentDevice) ? <CellStatus mac={dev.macAddress} /> : null}
              {dev.lastData ? <CreateWebcam userActions={userActions} user={user} currentDevice={dev} deviceActions={deviceActions} /> : null}
            </div>
            { getThemeObj().sharePublicly &&
              (<div className="share-wrap">
                <a onClick={() => this.setState({ open: 'aw', showShare: true })} className="btn btn-green"><i className="glyphicon glyphicon-share-alt"></i></a>
              </div>)
            }
            <div className="last-updated">
              {lastUpdated}
            </div>
          </header>
        </div>
        <div className="connections block">
          <div className="tabs">
            {this._tabLine()}

          </div>
          <div className={classNames("content", { open: open })}>
            {this._tabContent()}
          </div>
        </div>
      </div>
    )
  }
}

DeviceLine.displayName = 'DeviceLine'
