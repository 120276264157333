
const OPACITY = 45
export const MAP_LAYER_CONFIG = {
  radar: {
    // aerisLayers: [`radar:60`],
    accuweather: ['https://api.accuweather.com/maps/v1/radar/globalSIR/zxy/{z}/{x}/{y}.png'],
    labelParam: 'hourlyrainin',
    type: 'main'
  },
  temp: {
    // aerisLayers: [`temperatures:${OPACITY}`],
    accuweather: ['https://api.accuweather.com/maps/v1/models/gfs/zxy/DATE/{z}/{x}/{y}.png?display_products=26-1010'],
    labelParam: 'tempf',
    type: 'main'
  },
  wind: {
    accuweather: ['wind'],
    // aerisLayers: [`wind-speeds:${OPACITY}`, `wind-dir:${OPACITY}`],
    labelParam: 'windgustmph',
    rotate: ['-', ['number', ['get', 'winddir'], 0], 180],
    type: 'main'
  },
  fires: {
    aerisLayers: [`fires-obs-icons:${OPACITY}`],
    title: 'Fires',
    labelParam: 'tempf',
    type: 'plus',
    endpoint: 'fires'
  },
  cyclones: {
    aerisLayers: [`tropical-cyclones:${OPACITY}`],
    title: 'Tropical Cyclones',
    labelParam: 'windgustmph',
    rotate: ['-', ['number', ['get', 'winddir'], 0], 180],
    type: 'plus',
    endpoint: 'tropicalcyclones'
  },
  snow: {
    aerisLayers: [`snow-depth-global:${OPACITY}`],
    title: 'Snow Depth',
    labelParam: 'tempf',
    type: 'plus',
    endpoint: 'conditions'
  },
  // road: {
  //   aerisLayers: [`road-conditions:${OPACITY}`],
  //   title: 'Road Conditions',
  //   labelParam: 'hourlyrainin',
  //   type: 'plus'
  // },
  air: {
    // accuweather: ['https://api.accuweather.com/maps/v1/current/airquality/PlumeIndex/zxy/DATE/{z}/{x}/{y}.png'],
    aerisLayers: [`air-quality-index:${OPACITY}`],
    title: 'Air Quality',
    labelParam: 'tempf',
    type: 'plus',
    endpoint: 'airquality/index'
  },
  river: {
    aerisLayers: ['river-observations:100'],
    title: 'River Gauge',
    labelParam: 'hourlyraininz', // hide dots
    type: 'plus',
    endpoint: 'rivers'
  }
}
export const getLayerParam = layer => {
  if (layer === '') return 'tempf'
  return MAP_LAYER_CONFIG[layer].labelParam
}

// accuweather air colors
// air: {
//   title: 'Air Quality Index',
//   info: 'Air Quality Index, global, updates every 12 hours',
//   items: [
//     { color: '#6116f5', label: 'Dangerous', range: [301, 500] },
//     { color: '#bc78dd', label: 'Very Unhealthy', range: [201, 300] },
//     { color: '#d7466c', label: 'Unhealthy', range: [151, 200] },
//     { color: '#f3bb42', label: 'Poor', range: [101, 150] },
//     { color: '#70d066', label: 'Fair', range: [51, 100] },
//     { color: '#61ccfa', label: 'Excellent', range: [0, 50] }
//   ]
// },
export const MAP_LEGENDS = {
  air: {
    title: 'Air Quality Index',
    info: 'Air Quality Index, global, updates every 12 hours',
    items: [
      { color: '#7e0023', label: 'Hazardous', range: [301, 500] },
      { color: '#8f3f97', label: 'Very Unhealthy', range: [201, 300] },
      { color: '#ff0000', label: 'Unhealthy', range: [151, 200] },
      { color: '#ff7e00', label: 'Unhealthy for Sensitive Groups', range: [101, 150] },
      { color: '#ffff00', label: 'Moderate', range: [51, 100] },
      { color: '#00e400', label: 'Good', range: [0, 50] }
    ]
  },
  river: {
    title: 'Maximum Observed Stage',
    info: 'River and Lake Gauge observations from the NOAA Advanced Hydrologic Prediction Service (AHPS) US coverage, updates hourly',
    items: [
      { color: '#cc33ff', label: 'Major Flooding', statusCodes: [13] },
      { color: '#ff0000', label: 'Moderate Flooding', statusCodes: [11] },
      { color: '#ff9900', label: 'Minor Flooding', statusCodes: [9] },
      { color: '#ffff00', label: 'Near Flood Stage', statusCodes: [7] },
      { color: '#01ff00', label: 'No Flooding', statusCodes: [5] },
      { color: '#72afe9', label: 'Flood Category Not Defined', statusCodes: [1] },
      { color: '#906320', label: 'At or Below Low Water Threshold', statusCodes: [3] },
    ]
  },
  snow: {
    title: 'Snow Depth (in)',
    info: 'Estimated snow depth, global, updates every 6 hours',
    items: [
      { color: '#8f4141', label: '800' },
      { color: '#9f294a', label: '300' },
      { color: '#b10e72', label: '200' },
      { color: '#cb00c2', label: '100' },
      { color: '#9900ca', label: '70' },
      { color: '#671dd3', label: '40' },
      { color: '#3b37c3', label: '25' },
      { color: '#517bd3', label: '10' },
      { color: '#56a8cc', label: '5' },
      { color: '#6cebe7', label: '2' },
      { color: '#b4cece', label: '1' },
      { color: '#dbd9bd', label: '0' },
    ]
  },
  temp: {
    title: 'Temperature (°F)',
    info: 'Updates hourly',
    items: [
      { color: '#4e0a08', label: '130' },
      { color: '#6b120a', label: '120' },
      { color: '#7f170e', label: '110' },
      { color: '#bc271a', label: '100' },
      { color: '#a83819', label: '90' },
      { color: '#a0571e', label: '80' },
      { color: '#9d7826', label: '70' },
      { color: '#ab8b2d', label: '60' },
      { color: '#3b8728', label: '50' },
      { color: '#479e2e', label: '40' },
      { color: '#439688', label: '30' },
      { color: '#3579bf', label: '20' },
      { color: '#351fe6', label: '10' },
      { color: '#260392', label: '0' },
      { color: '#363384', label: '-10' },
      { color: '#63457e', label: '-20' },
      { color: '#522f6e', label: '-30' },
      { color: '#7d176c', label: '-40' },
      { color: '#4a093f', label: '-50' }
    ]
  },
  wind: {
    title: 'Wind Speed (mph)',
    info: 'Wind speed updates hourly. Wind direction updates every 30 mins.',
    items: [
      { color: '#ba0908', label: '> 125' },
      { color: '#000000', label: '75' },
      { color: '#2e1643', label: '70' },
      { color: '#55287b', label: '60' },
      { color: '#7437a8', label: '50' },
      { color: '#8b41c9', label: '40' },
      { color: '#a56dd2', label: '30' },
      { color: '#bf98db', label: '20' },
      { color: '#d1b8df', label: '13' },
      { color: '#dfd1dc', label: '<7' }
    ]
  }
}
