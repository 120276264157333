import { curry, path, pluck, reverse } from 'ramda'
import { isIosApp, isAndroidApp, isApp } from '../../common/ambient'

// Warning; array MUST be ordered from lowest tier to highest tier
export const CAM_TIERS = [{
  label: 'Standard',
  timelapseRes: '640x320',
  price: 'Free',
  regenRate: '4 times per day',
  banner: 'Standard',
  sampling: '5 minutes',
  storage: '1 day',
  mapIcon: 'Standard',
  productName: '',
  term: 'both'
}, {
  label: 'Silver Monthly',
  price: '$4.99/month',
  buyPrice: 4.99,
  timelapseRes: '1280x720',
  regenRate: '6 times per day',
  banner: 'HD',
  sampling: '3 minutes',
  storage: '30 days',
  mapIcon: 'Silver',
  productName: 'awn-camera-tier1-monthly',
  term: 'monthly'
}, {
  label: 'Silver Annual',
  price: '$4.16/month', // really $49.92
  buyPrice: 49.99,
  timelapseRes: '1280x720',
  regenRate: '6 times per day',
  banner: 'HD',
  sampling: '3 minutes',
  storage: '30 days',
  mapIcon: 'Silver',
  productName: 'awn-camera-tier1-yearly',
  term: 'yearly'
}, {
  label: 'Gold Monthly',
  price: '$9.99/month',
  buyPrice: 9.99,
  timelapseRes: '1920x1080',
  regenRate: '8 times per day',
  banner: 'Full HD',
  sampling: '3 minutes',
  storage: '60 days',
  mapIcon: 'Gold',
  productName: 'awn-camera-tier2-monthly',
  term: 'monthly'
}, {
  label: 'Gold Annual',
  price: '$8.33/month', // really $99.96
  buyPrice: 99.99,
  timelapseRes: '1920x1080',
  regenRate: '8 times per day',
  banner: 'Full HD',
  sampling: '3 minutes',
  storage: '60 days',
  mapIcon: 'Gold',
  productName: 'awn-camera-tier2-yearly',
  term: 'yearly'
}]

const camProductNames = pluck('productName', CAM_TIERS)

export const USE_NEW_CELL_PLAN = Date.now() > new Date('2024-10-01 00:00:00').getTime()

export const CELL_SUBCRIPTIONS = [{
  productName: 'kestrelmet-one-year-subscription-new',
  label: 'KestrelMet Standard 1 Year Cellular Plan',
  price: 99.99,
  SKU: 281055
}, {
  productName: 'kestrelmet-one-year-subscription',
  label: 'KestrelMet Standard 1 Year Cellular Plan',
  price: 49.99, // really 49.92
  SKU: 281055
}, {
  productName: 'kestrelmet-three-year-subscription',
  label: 'KestrelMet Standard 3 Year Cellular Plan',
  price: 150,
  SKU: 281057
}, {
  productName: 'kestrelmet-pro-one-year-subscription',
  label: 'KestrelMet Pro 1 Year Cellular Plan',
  price: 120,
  SKU: 281053
}, {
  productName: 'kestrelmet-pro-three-year-subscription',
  label: 'KestrelMet Pro 3 Year Cellular Plan',
  price: 360,
  SKU: 281058
}]

// awn-plus-account-monthly
// awn-plus-account-yearly
// awn-pro-account-monthly
// awn-pro-account-yearly
export const USER_SUBSCRIPTIONS = [{
  productName: 'awn-plus-account-monthly',
  price: '$4.99/month'
}, {
  productName: 'awn-plus-account-yearly',
  price: '$49.99/year'
}, {
  productName: 'awn-pro-account-monthly',
  price: '$9.99/month'
}, {
  productName: 'awn-pro-account-yearly',
  price: '$99.99/year'
}]

export const displayPriceForSubscription = (sub) => {
  if (sub.price) {
    return sub.currency + sub.price
  }
  if (sub.pricingInfo) {
    return sub.pricingInfo.currency + sub.pricingInfo.amount
  }
  let productName = sub.productName
  const subscription = [...USER_SUBSCRIPTIONS, ...CELL_SUBCRIPTIONS, ...CAM_TIERS].find(entry => entry.productName === productName)
  if (typeof produceName === 'object') {
    productName = productName.productName
  }
  return typeof subscription.price === 'string' ? subscription.price : '$' + subscription.price.toFixed(2)
}
export const isCellularDevice = (device) => path(['lastData', 'stationtype'], device) === 'NK_KestrelMet6000'
export const isCellularSubscription = (productName) => CELL_SUBCRIPTIONS.find(entry => entry.productName === productName)
export const hasCellularSubscription = curry((user, macAddress) => {
  return (
    (path(['info', 'payments', 'subscriptions'], user) || [])
      .filter(sub => sub.macAddress === macAddress)
      .filter(sub => isCellularSubscription(sub.productName))
      .length > 0
  )
})
export const hasCellularDevice = (devices) => {
  return (
    (devices || [])
      .filter(device => isCellularDevice(device))
      .length > 0
  )
}
export const hasRainWiseStatus = (device) =>
  path(['lastData', 'stationtype'], device) === 'NK_KestrelMet6000' ||
  path(['lastData', 'stationtype'], device) === 'NK_KestrelMet6000W'

export const userHasPayments = path(['info', 'payments'])

export const userHasCardOnFile = path(['info', 'payments', 'defaultPaymentMethod'])
export const deviceHasSubscription = curry((user, macAddress) => {
  return (path(['info', 'payments', 'subscriptions'], user) || []).find(sub => sub.macAddress === macAddress)
})

export const deviceHasThisSub = curry((user, macAddress, productName) => {
  return (
    (path(['info', 'payments', 'subscriptions'], user) || [])
      .filter(sub => sub.macAddress === macAddress)
      .find(sub => sub.productName === productName)
  )
})

export const deviceCamTierI = curry((user, macAddress) => {
  const lastTierIndex = CAM_TIERS.length - 1
  const i = reverse(camProductNames).findIndex((prodName) => deviceHasThisSub(user, macAddress, prodName))
  return i > -1 ? lastTierIndex - i : 0
})

export const deviceCamTierObj = curry((user, macAddress) => {
  const i = deviceCamTierI(user, macAddress)
  const ret = Object.assign({ tier: i }, CAM_TIERS[i])
  return ret
})

// Stripe Subscription Object
// status enum
// Possible values are incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid.
// also "free" which is created internally
export const deviceSubscriptionStatus = curry((user, macAddress, productName) => {
  const sub = (path(['info', 'payments', 'subscriptions'], user) || [])
    .filter(sub => sub.macAddress === macAddress)
    .find(sub => sub.productName === productName)
  return (sub ? sub.status : '')
})

const activeSubscriptionStatuses = ['active', 'trialing', 'past_due', 'free']
const subIsActive = sub => {
  if (typeof sub.provisionAccess !== 'undefined') {
    return sub.provisionAccess
  }
  return activeSubscriptionStatuses.includes(sub.status)
}

// this can take the user state OR the user object (contained in user.info)
export const isPlus = user => {
  const theUser = path(['info'], user) || user
  if (!theUser) return false
  const plusSub = getPlusSub(user)
  if (plusSub && subIsActive(plusSub)) {
    return plusSub
  }
  if (theUser.roles && theUser.roles.includes('awn-plus')) {
    return {
      productName: 'awn-plus-free'
    }
  }
  return false
}

export const getPlusSub = user => {
  const theUser = path(['info'], user) || user
  if (!theUser) return false
  const subscriptions = path(['payments', 'subscriptions'], theUser) || []
  return subscriptions.find(sub => /^awn-plus-/.test(sub.productName))
}
export const plusIsTrial = user => {
  return getPlusSub(user) && getPlusSub(user).status === 'trialing'
}

export const subIsAndroid = sub => {
  return sub.googleProductId
}
export const subIsIos = sub => {
  return sub.appleProductId
}
export const subIsIap = sub => {
  return subIsAndroid(sub) || subIsIos(sub)
}

export const subIsIosDowngradedAnnual = sub => {
  return subIsIos(sub) && /yearly/.test(sub.productName) && sub.willDowngradeAtPeriodEnd
}
export const subIsMonthly = sub => {
  if (/monthly/.test(sub.productName)) {
    return true
  }
  if (subIsIosDowngradedAnnual(sub)) {
    return true
  }
  return false
}

export const subStatusDisplay = sub => {
  if (sub.status === 'trialing') {
    return 'Free Trial'
  }
  return sub.status.charAt(0).toUpperCase() + sub.status.slice(1).replace('_', ' ')
}

// only returns something is the app has configured the product details
export const getAwnPlusAppPrice = (device, annual) => {
  const appSubscriptionId = annual ? 281025 : 281024
  if (isApp()) {
    if (device.appProductDetails && Array.isArray(device.appProductDetails)) {
      const p = device.appProductDetails.find(item => parseInt(item.id, 10) === appSubscriptionId)
      if (p && p.displayPrice) {
        return p.displayPrice.replace(/USD\s+/, '$')
      }
    }
    return '- -'
  }
}

export const freeTrialUsed = (user, subKey) => {
  return path(['info', 'payments', 'freeTrialsUsed', subKey], user)
}

export const showAwnFreeTrial = user => {
  const awnFreeTrialUsed = freeTrialUsed(user, 'awn-plus-free-trial')
  const freeTrialUntil = moment('2024-06-30T23:59:59.000Z')
  return Date.now() < freeTrialUntil.valueOf() && !awnFreeTrialUsed
}

export const awnPlusAnnualMonthlySwitchAvail = user => {
  const sub = user.plus
  if (!sub) return false
  return (isIosApp() && subIsIos(sub)) ||
  (isAndroidApp() && subIsAndroid(sub)) ||
  (!isApp() && !subIsIap(sub))
}

export { default as AddCard } from './AddCard'
export { default as AllPayments } from './AllPayments'
export { default as DisplayCard } from './DisplayCard'
export { default as DeleteCard } from './DeleteCard'
export { default as ManageSubscriptions } from './ManageSubscriptions'
export { default as DisplaySubscription } from './DisplaySubscription'
export { default as AddSubscription } from './AddSubscription'
export { default as EnhancedCamModal } from './EnhancedCamModal'
export { default as CellPlanModal } from './CellPlanModal'
export { default as PlusSubscriptionModal } from './PlusSubscriptionModal'
export { default as SubscribeProcess } from './SubscribeProcess'
