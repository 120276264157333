import React, { useCallback, useEffect, useState, useRef } from 'react'
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import bindAllActions from '../../common/bindAllActions'
import { findMyLocation, isCordova, userCanSocialPost } from '../../common/ambient'
import { append, nth, path } from 'ramda'

import {
  ErrorBoundary,
  Map
} from '../../components/'
import {
  OutsideBar
} from '../device/'
import {
  LocationAutocomplete,
  BottomSheet
} from '../common/'
import {
  ForecastSettings
} from './'
import { useSetForecastOpen, useSetMapLocation, useToggleAppClass } from '../common/redux/hooks'
import { coordsAreEqual, isIosCordovaOrIosBrowser } from '../../common/ambient/utils'
// import PropTypes from 'prop-types';

const MobileHomePage = ({ user, device, socialActions, social, deviceActions, userActions }) => {
  const { sidebar } = device
  const { forecastOpen, setForecastOpen } = useSetForecastOpen()
  const [search, setSearch] = useState(false)
  const mapRef = useRef()
  const { toggleAppClass } = useToggleAppClass()
  const [searchFocused, setSearchFocused] = useState(false)
  const { mapLocation, setMapLocation } = useSetMapLocation()
  const [location, setLocation] = useState(null)
  const [mapBounds, setMapBounds] = useState(null)
  const [mapCenter, setMapCenter] = useState(null)
  const [showSettings, setShowSettings] = useState(false)
  // const [forecastOpen, setForecastOpen] = useState(false)
  const closeSocial = useCallback(() => {
    deviceActions.setSidebar(false)
    deviceActions.hoverDevice(false)
    deviceActions.focusDevice(null)
  }, [deviceActions])
  // init
  useEffect(() => {
    closeSocial()
    if (!isCordova()) {
      document.body.style.position = 'fixed'
    }
    document.body.style.height = '100vh'
    document.body.style.width = '100%'
    document.body.style.overflow = 'hidden'

    // test notification

    return () => {
      document.body.style.position = null
      document.body.style.height = null
      document.body.style.width = null
      document.body.style.overflow = null
    }
  }, [closeSocial])

  // respond to changes in location and mapLocation
  useEffect(() => {
    const doSearch = (path(['geo', 'coordinates'], location) && !coordsAreEqual(location, mapLocation) && search) || // searching
      (!path(['geo', 'coordinates'], location) && mapLocation && !search) // mapLocation will be here or will be populated via fetchInfo in userListener the first time
    if (doSearch) {
      const loc = location || mapLocation
      setLocation(loc)
      setMapCenter(loc.geo.coordinates)
      setSearch(false)
      setMapLocation(loc)
      const deviceArgs = {
        $publicNear: {
          coords: loc.geo.coordinates
        },
        rank: 2.1,
        $limit: 3
      }
      let interestingCoords = []
      deviceActions.fetchDevice(deviceArgs)
        .then(res => {
          interestingCoords = res.data.map(d => d.info.coords.geo.coordinates)
          const args = {
            geo: {
              $near: {
                $geometry: loc.geo,
                $maxDistance: 160 * 1000
              }
            },
            status: {
              $in: ['boosted', 'published']
            },
            expiresAt: { $gte: Date.now() },
            $limit: 3
          }
          return socialActions.fetchPosts(args)
        })
        .then(res => {
          if (res && res.data && res.data.length > 0) {
            interestingCoords = interestingCoords.concat(res.data.map(p => p.geo.coordinates))
          }
          const lons = interestingCoords.map(nth(0))
          const lats = interestingCoords.map(nth(1))
          const bounds = [
            [Math.min(...lons), Math.min(...lats)],
            [Math.max(...lons), Math.max(...lats)]
          ]
          setMapBounds(bounds)
        })
    }
  }, [deviceActions, socialActions, location, setMapLocation, mapLocation, setLocation, search])

  // search-mode
  useEffect(() => {
    toggleAppClass('search-mode', search)
    if (search) {
      closeSocial()
    }
  }, [search, toggleAppClass, closeSocial])
  // sidebar is opening
  useEffect(() => {
    if (sidebar) {
      setSearch(false)
    }
  }, [setSearch, sidebar])

  // forecast is closing
  useEffect(() => {
    if (!forecastOpen && mapRef.current && isIosCordovaOrIosBrowser()) {
      setTimeout(() => mapRef.current.resize(), 300)
    }
    deviceActions.setThing('forecastOpen', forecastOpen)
    if (!forecastOpen) {
      setShowSettings(false)
    }
  }, [forecastOpen, mapRef, deviceActions, setShowSettings])

  const theCoords = path(['geo', 'coordinates'], mapLocation)
  if (!theCoords) {
    return null
  }
  const onPlaceSelected = loc => {
    console.log(loc)
    // reset a lot of stuff
    closeSocial()
    if (!coordsAreEqual(loc, mapLocation)) {
      setMapCenter(false)
      setSearch(true)
      // set the location
      setLocation(loc)
    } else {
      setMapCenter(loc.geo.coordinates)
      setSearch(false)
    }
  }
  return (
    <div className={classNames('user-mobile-home-page', {
      ios: isIosCordovaOrIosBrowser(),
      search,
      focused: searchFocused && !location,
      forecast: forecastOpen,
      settings: showSettings
    })}
    >
      <a
        className='back back-map' onClick={() => {
          setForecastOpen(false)
        }}
      >Map
      </a>
      <a
        className='back back-search' onClick={() => {
          if (showSettings) {
            setShowSettings(false)
          } else {
            setSearch(false)
            closeSocial()
          }
        }}
      >Back
      </a>
      <ErrorBoundary
        location={location}
        user={user}
        userActions={userActions}
      >
        <Map
          onMapLoad={map => {
            mapRef.current = map
          }}
          onDeviceClick={(d, evt) => {
            // we do this to make the map dot pulse
            // the pulsing is really designed for desktop
            deviceActions.hoverDevice(d)
            evt.map._hoverDevice = null // not good practice but waygd?
          }}
          onViewDashboardClick={() => console.log('hi')}
          coords={mapCenter}
          mapBounds={mapBounds}
          setSidebarCheck={evt => {
            return ['social', 'webcam', 'alert', 'video', 'video-enhanced'].includes(evt.features[0].layer.id)
          }}
        />
      </ErrorBoundary>
      <a
        className='search-btn' onClick={() => {
          setLocation(null)
          setSearch(true)
          setForecastOpen(false)
        }}
      />
      <LocationAutocomplete
        className='find-my-location'
        onPlaceSelected={onPlaceSelected}
      />
      <CSSTransition
        in={search}
        timeout={400}
        classNames='search-wrap'
      >
        <div className={classNames('search-wrap')}>
          <SwipeableBottomSheet
            shadowTip={false}
            overlay={false}
            overflowHeight={0}
            open={search}
            onChange={isOpen => {
              if (!isOpen) {
                setSearch(false)
              }
            }}
          >
            <LocationAutocomplete
              key='search'
              onClose={() => setLocation(null)}
              onChange={val => {
                if (val === '') {
                  setSearchFocused(false)
                } else {
                  setSearchFocused(true)
                }
              }}
              onPlaceSelected={onPlaceSelected}
            />
          </SwipeableBottomSheet>
        </div>
      </CSSTransition>
      <CSSTransition
        in={sidebar}
        timeout={400}
        classNames='sidebar-wrap'
      >
        <SwipeableBottomSheet
          shadowTip={false}
          overlay={false}
          overflowHeight={0}
          open={sidebar}
          onChange={isOpen => {
            if (!isOpen) {
              closeSocial()
            }
          }}
        >
          <OutsideBar />
        </SwipeableBottomSheet>
      </CSSTransition>
      <CSSTransition
        in={!search && !sidebar}
        timeout={400}
        classNames='bottom-sheet-wrap'
      >
        <>
          {showSettings && <ForecastSettings
            user={user}
            userActions={userActions}
          />}
          <BottomSheet
            open={forecastOpen}
            onChange={setForecastOpen}
            onShowSettingsChange={setShowSettings}
            update={!search && !sidebar}
            coords={theCoords}
            onPlaceSelected={onPlaceSelected}
          />
        </>
      </CSSTransition>
    </div>
  )
}

export default bindAllActions(MobileHomePage)

MobileHomePage.propTypes = {}
MobileHomePage.defaultProps = {}

MobileHomePage.displayName = 'MobileHomePage'
